// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import $ from 'jquery';
import {initBootstrap} from "./bootstrap";
import carousel from 'slick-carousel';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

//Featueed Roles Slider

$('.featured__slides').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  variableWidth: false,
  infinite: true,
  responsive: [{
    breakpoint: 768,
    settings: {
      slidesToShow: 1
    }
  }, {
    breakpoint: 520,
    settings: {
      slidesToShow: 1
    }
  }]
});

$('.slide-next').click(function(e){
  //e.preventDefault(); 
  $('.featured__slides').slick('slickNext');
} );